import React from 'react';
import { NavLink } from "react-router-dom";
import './NavMenu.css';
import logo_white from '../../assets/img/logo_white.svg';
import logo2_white from '../../assets/img/logo2_white.svg';

// be sure to import all other overlay images here for the hover effect over an area of the menu (dimensions = 1920*1200)
// be sure to import all other overlay images here for the hover effect over an area of the menu (dimensions = 1920*1200)
// be sure to import all other overlay images here for the hover effect over an area of the menu (dimensions = 1920*1200)
// be sure to import all other overlay images here for the hover effect over an area of the menu (dimensions = 1920*1200)

const NavMenu = () => {

    function navMenuClose() {
        // setTimeout(() => {
        //     const container = document.querySelector('.container');
        //     container.style.height = '100%'; 
        // }, 500);
        
        const navMenu = document.querySelector('.nav-menu');
        navMenu.classList.remove('nav-menu--active');
        navMenu.style.height = '0';
        // navMenu.style.transformOrigin = 'bottom';
        // navMenu.style.translateY = '200px';
        // navMenu.style.top = '0';

        const navMenuLogo = document.querySelector('.nav-menu__logo');
        navMenuLogo.style.opacity = '0';
        navMenuLogo.style.transition = 'opacity 0s ease-in 0s';

        const navMenuBtn = document.querySelector('.nav-menu__btn-close');
        navMenuBtn.style.opacity = '0';
        navMenuBtn.style.transition = 'opacity 0s ease-in 0s';

        const navMenuList = document.querySelector('.nav-menu__list');
        navMenuList.style.opacity = '0';
        navMenuList.style.transition = 'opacity .25s ease-out .1s';
        
        const burgerMenu = document.querySelector('.header__burger-menu-btn');
        burgerMenu.style.visibility = 'visible';
        burgerMenu.style.transition = 'visibility .35s ease-in';
    }

    return (
        <div className="nav-menu" onClick={navMenuClose}>
            <NavLink to="/" exact onClick={navMenuClose}><img src={logo2_white} alt="logo" className="nav-menu__logo"/></NavLink>
            <button className="nav-menu__btn-close" onClick={navMenuClose}>
                <span className="nav-menu__btn-close__text">Close</span>
                <div className="nav-menu__btn-close__line"></div>
                <div className="nav-menu__btn-close__line"></div>
            </button>
            <nav>
                <ul className="nav-menu__list">
                    <h4 className="nav-menu__title">Menu</h4>
                    <li>
                        <NavLink to="/" exact className="nav-menu__link" activeClassName="nav-menu__link--current" onClick={navMenuClose}>Home</NavLink>
                    </li>
                    <li>
                        <NavLink to="/about" className="nav-menu__link" activeClassName="nav-menu__link--current" onClick={navMenuClose}>About</NavLink>
                    </li>
                    <li>
                        <NavLink to="/skills" className="nav-menu__link" activeClassName="nav-menu__link--current" onClick={navMenuClose}>Skills</NavLink>
                    </li>            
                    <li>
                        <NavLink to="/projects" exact className="nav-menu__link" activeClassName="nav-menu__link--current" onClick={navMenuClose}>Projects</NavLink>
                    </li>
                    <li>
                        <NavLink to="/contact" className="nav-menu__link" activeClassName="nav-menu__link--current" onClick={navMenuClose}>Contact</NavLink>
                    </li>
                </ul>
            </nav>    
        </div>
    )
}

export default NavMenu;


import React from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';
import logo from '../../assets/img/logo.svg';
import logo2 from '../../assets/img/logo2.svg';

const Header = () => {
    
    function navMenuOpen() {
        // document.body.style.overflowY = 'hidden';
        // const container = document.querySelector('.container');
        // container.style.overflowY = 'hidden';
        // container.style.height = '100vh';
        const navMenu = document.querySelector('.nav-menu');
        navMenu.classList.add('nav-menu--active');
        navMenu.style.height = '100vh';
        // navMenu.style.overflowY = 'hidden';
        // navMenu.style.top = '0';
        // navMenu.style.transformOrigin = 'bottom';
        const navMenuLogo = document.querySelector('.nav-menu__logo');
        navMenuLogo.style.opacity = '1';
        // navMenuLogo.style.transition = 'visibility .25s ease-in .1s';
        navMenuLogo.style.transition = 'opacity .95s ease-in .3s';
        const navMenuBtn = document.querySelector('.nav-menu__btn-close');
        navMenuBtn.style.opacity = '0.7';
        navMenuBtn.style.transition = 'opacity .95s ease-in .3s';
        const navMenuList = document.querySelector('.nav-menu__list');
        navMenuList.style.opacity = '1';
        navMenuList.style.transition = 'opacity .95s ease-in .3s';
        const burgerMenu = document.querySelector('.header__burger-menu-btn');
        burgerMenu.style.visibility = 'hidden';
        burgerMenu.style.transition = 'visibility .35s ease-in';
    }

    return (
        <div>
            <header className="header">
                <NavLink to="/"><img className="header__logo" src={logo2} alt="logo"/></NavLink>
                <button className="header__burger-menu-btn" onClick={navMenuOpen}>
                    <span className="header__burger-menu-btn__text">Menu</span>
                    <div className="header__burger-menu-btn__line"></div>
                    <div className="header__burger-menu-btn__line"></div>
                    <div className="header__burger-menu-btn__line"></div>
                </button>
            </header>
        </div>
    )
}

export default Header;
import React from 'react';
import './Footer.css';
import Heart from '../../assets/img/heart.png';
// import FooterLogo from '../../assets/img/logo_small.svg';
import FooterLogo from '../../assets/img/logo_footer.svg';

const Footer = () => {

    function nowDate() {
        const currentDate = new Date();    
        const currentYear = currentDate.getFullYear();
        return currentYear;
    }

    return (
        <div>
            <footer className="footer">
                <img src={FooterLogo} alt="heart" className="footer__logo"/>
                <span className="footer__text">&copy; 2019 — {nowDate()} sdholdsworth.co.uk<br />
                Made with<img src={Heart} alt="heart" className="footer__icon"/>by Sam Holdsworth in Leeds, UK.</span>
                <h3 className="heading--bold heading--medium footer__title">Web & UI</h3>
                <h3 className="heading--bold heading--medium footer__title-vertical">Developer</h3>
            </footer>
        </div>
    )
}

export default Footer;
import React from 'react';
import './Projects.css';
import { Helmet } from 'react-helmet';

const Projects = () => {
    return (
        <div>
            <Helmet>
                <meta name="description" content="A showcase of my current Web & UI portfolio. Here you will find everything from the initial designs to a full build demonstration for each of my projects."/>
                <title>Projects | SH Portfolio</title>
                <link rel="canonical" href="https://sdholdsworth.co.uk/projects" />
            </Helmet>
            <h3>PROJECTS Component</h3>
        </div>
    )
}

export default Projects;
import React from 'react';
import './Skills.css';
import { Helmet } from 'react-helmet';

const Skills = () => {
    return (
        <div>
            <Helmet>
                <meta name="description" content="Here is where you will get a feel for what skills I possess, my areas of expertise and what I currently specialize in. I'm always looking to expand on my knowledge."/>
                <title>Skills | SH Portfolio</title>
                <link rel="canonical" href="https://sdholdsworth.co.uk/skills" />
            </Helmet>
            <h3>SKILLS Component</h3>
        </div>
    )
}

export default Skills;
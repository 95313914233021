import React from 'react';
import './Home.css';
import { Helmet } from 'react-helmet';

const Home = () => {
    return (
        
        <div id="home">
            <Helmet>
                <meta name="description" content="A talented web enthusiast. A multiskilled, passionate Web & UI Developer. Find out more about me and take a look at some of my great work."/>
                <title>Home | SH Portfolio</title>
                <link rel="canonical" href="https://sdholdsworth.co.uk" />
            </Helmet>
            <h3>HOME Component</h3>
        </div>
    )
}

export default Home;
import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './App.css';
import NavMenu from './components/NavMenu/NavMenu';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import About from './components/About/About';
import Skills from './components/Skills/Skills';
import Projects from './components/Projects/Projects';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import TEMP_Checklist from './components/TEMP_Checklist/TEMP_Checklist';
import backToTop from './assets/img/back_to_top.svg';


// (function scrolledLocation() {
//   window.addEventListener('scroll', () => {
//   console.log(`POSITION: ${window.scrollY}`);
// }) 
// })();




const App = () => {

  let posY = () => {
    var scrolledLocation = window.scrollY;
    var topBtn = document.querySelector('.back-to-top-btn');
    if(scrolledLocation >= 350) {
      topBtn.classList.add('back-to-top-btn--active')
    } else {
      topBtn.classList.remove('back-to-top-btn--active')
    }
  }

  window.addEventListener('scroll', posY);

  return (
    <Router>
      <Header />
      <div className="container">
        <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/about" component={About} />
            <Route path="/skills" component={Skills} />
            <Route path="/projects" component={Projects} exact />
            <Route path="/contact" component={Contact} />
            <Route path="/checklist" component={TEMP_Checklist} />
          </Switch>    
      </div>
      <a href="#" className="back-to-top-btn"><img src={backToTop} className="back-to-top-btn__image"/></a>
      <Footer />
      <NavMenu />
    </Router>
  );
}

export default App;

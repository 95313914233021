import React from 'react';
import './Contact.css';
import { Helmet } from 'react-helmet';

const Contact = () => {
    return (
        <div>
            <Helmet>
                <meta name="description" content="Like what you see? if so, please do not hesitate to get in contact with me today. I would love to hear from you to discuss any potential opportunities."/>
                <title>Contact | SH Portfolio</title>
                <link rel="canonical" href="https://sdholdsworth.co.uk/contact" />
            </Helmet>
            <h3>CONTACT Component</h3>
        </div>
    )
}

export default Contact;
import React from 'react';
import './TEMP_Checklist.css';

const TEMP_Checklist = () => {

    return (
        <div>
            <h5 className="temp">PROJECT PLAN / ORDERING OF TASKS</h5>
            <h6 className="temp">Functionality</h6>
          <ol className="temp">
          <li>Routing (React Router)
                <ol className="temp">
                    <li>See "DEVED" react router video (very important - modern approach & may also help with nested routing i.e. /projects/project_xx) & old portfolio project for more on this</li>
                    <strike><li>Test Strike-through</li></strike>
                </ol>
            </li>
            
            <li>Page Titles, Meta Data etc..
                <ol className="temp">
                    <li>Use react HELMET in a similar/improved manner to current portfolio</li>
                </ol>
            </li>

            <li>Nav Menu
              <ol className="temp">
                <li>Styling</li>
                <li>Positioning - height, width, exact overlay Positioning of items, prevent menu scroll + 100vh menu etc...</li>
                <li>Animations - transitions, fades etc... of all menu items</li>
              </ol>
            </li>

            <li>Side-Wide Styling & Animations
                <ol className="temp">
                    <li>Spinners, Loaders, Parallax-scrolling, Hover-states, focus & active states, new touch states, GSAP integration etc...</li>
                    <li>Smooth scroll (inc. Smoothscroll-polyfill fix)</li>
                    <li>Use of Font styles, letter spacing, word spacing</li>
                    <li>Minimal positive text (see expressions and other docs generated already for help with this)</li>
                    <li>DO NOT FORGET TO RUN ALL CSS THROUGH <span className="highlight" style={{fontWeight: 900}}>"AUTO PRE-FIXER" ONLINE TOOL</span> - every single CSS file when EVERYTHING is absolutely 100% complete</li>
                </ol>
            </li>

            <li>Item 4 - RANDOM ODDS & SODS
                <ol className="temp">
                    <li>I like to focus on creating meaningful, simplistic and minimalist clean and clear user interfaces........

                        Not just what appears visually, I also work very hard to ensure that clean, tidy code is well written and optimized to the best standard... And is not just written randomly. I follow certain standards when I code as well, for example the BEM convention, I find this to be of great value when it comes to keeping a codebase well organised. I am always looking for ways to improve things and thouroughly enjoy development and have done since the first days I stepped foot in this industry back in 2016........ 

                        ALSO BE SURE TO CHECK ALL EMAILS TO MYSELF, PREVIOUS PORTFOLIO'S, ANY PAPER LISTs of IDEAS, THE 2 WEBSITES I AM USING AS A REFERENCE AND LISTS IN SAMSING NOTES, POWERPOINTS ETC... TO ENSURE I DONT MISS ANYTHING... EVEN IF THE TEXT IS NOT FULLY CORRECT, IDEAS CAN STEM FROM THESE THINGS! ... BUT DONT OVERDO IT WITH WORDS AND RAMBLE ON TOO MUCH, 1-2 SENTENCES AND SMALL PARAGRAPHS EMPHASIZONG POINTS ONLY... PICTURES AND MY ACTUAL PROJECTS ARE THE MAIN THING PEOPLE WILL BE INTERESTED IN... MAYBE DO THE MAP THING FOR THE CONTACT ME SECTION... THEN SHOWS SKILLS OF CONNECTiNG TO GOOGLES API AND I CAN LEARN THIS... ETC... 
                    </li>
                    <li>Words of mastery and speaches for FINAL LATEST PORTFOLIO....<br />
                        Creative / creativity<br />
                        Innovative<br />
                        Inventor<br />
                        Originality<br />
                        Mastery<br />
                        Specialist<br />
                        Simplicity<br />
                        Sometimes less is more..........<br /><br />

                        I thoroughly enjoy designing and developing simplistic & minimalist unique user experiences and journeys... that tell a story(optional or find a better way to add this last part in or tell it on another paragraph)..........<br /><br /> 

                        Having worked closely with a talented team of user experience designers on a day-to-day basis... I have developed a great insight into UI/UX design strategies and have even completed some very good in-depth courses on Udemy in this subject area. This skill gained, together with my expertise in web development, makes me a highly valuable asset in the web development community..........<br /><br /> 

                        - logical, problem solving mindset from my and experience in the engineering industry, which i would say has definitely helped me fastrack myself in development / in the world of software development 
                    </li>
                    <li>
                        SKILLS PAGE: THink maybe just combine this with the developer resume idea... and showcase what done in personal + industry as combined skillsets maybe in secondary titles i.e. my projects & self learning plus in industry section (else if cannot see this working add /resume or /developer-resume area).
                    </li>
                </ol>
            </li>
          </ol>

          {/* <hr /><hr /><hr /><hr /><hr /><hr /><hr /><hr /><hr /><hr /><hr /><hr /><hr /><hr /><hr /><hr />
          <h6 className="heading--large heading--bold heading--gradient">Hi Sam Here!</h6>
          <h6 className="heading--large heading--normal heading--gradient">Hi Sam Here!</h6>
          <h6 className="heading--large heading--light heading--gradient">Hi Sam Here!</h6>
          <hr />
          <h6 className="heading--medium heading--bold heading--gradient">Hi Sam Here!</h6>
          <h6 className="heading--medium heading--normal heading--gradient">Hi Sam Here!</h6>
          <h6 className="heading--medium heading--light heading--gradient">Hi Sam Here!</h6>
          <hr />
          <h6 className="heading--small heading--bold heading--gradient">Hi Sam Here!</h6>
          <h6 className="heading--small heading--normal heading--gradient">Hi Sam Here!</h6>
          <h6 className="heading--small heading--light heading--gradient">Hi Sam Here!</h6>
          <hr />
          <p>This is just a PARAGRAPH test</p>
          <hr />
          <h6 className="heading--large heading--bold heading--gradient">I’m Sam Holdsworth, a multidisciplinary designer who focuses on telling my clients’ stories visually, through enjoyable and meaningful experiences. I specialize in responsive websites and functional user interfaces.</h6>
          <hr />
          <p><b><i>Some quote tests / ideas...</i></b></p>
          <h6 className="heading--large heading--bold heading--gradient">Hello. I’m Sam Holdsworth, a talented, multi-skilled, <u><i>UI & Web Developer</i></u>. With a breadth of design, user experience and web programming knowledge, I'm someone who likes to focus on telling stories visually, through creating enjoyable and meaningful experiences. 
          I specialize in the end-to-end design and development of fully responsive websites and the implementation of functional user interfaces.</h6>

          <p>and , . </p>
          <p>I am essentially a web developer. I have a great coding ability, and have experience working across the full-stack. I do however have more of a preference for front-end development and this is definitely my strongest skill. </p>
          <p>I'm illustrious in my current role...</p>
          <p>I am actively seeking a new, more exciting, more challenging role to take me to that next level in my career as a web developer. I am looking for the right company to help me achieve this; a company I can help share my expertise with and help shape the future with the new cutting edge technologies out there which are now available at our fingertips; greenfield or brownfield projects... you name it... I'll be excited to dive in deep and get my hands dirty!</p>
          <p>ingenuity is KEY in the way I construct my text within this site... do NOT ramble on make pages too word heavy!! - just needs to be just right and to a tee! - no room for negativity as well, on previous jobs - KURB this - only talk about things in a positive light... dont tell life story or mention anybodies name! - this could be seen as very unprofessional etc.., or my skill level, mention do research continually and I am always actively keeping my skills up-to-date, through online courses, reading forums and trying to keep ahead of the curve with new exciting technologies.</p> */}
        </div>
    )
}


export default TEMP_Checklist;